
.noData {
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 150px;
}

.noData .noDataIcon {
  opacity: 0.3;
  height: 200px;
}

.noData .noDataText {
  font-size: 30px;
  color: rgba(0, 0, 0, 0.3);
}
