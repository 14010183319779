.uploadVideoAsset {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: white;
  overflow: hidden;
  overflow-y: auto;
}

.uploadVideoAssetHeader {
  background-color: #eee;
  width: 100%;
}

.uploadVideoAssetContent {
  padding: 15px 20px;
}

.uploadBlock {
  margin-top: 20px;
}

.uploadBlock .ant-upload-wrapper {
  display: block;
}
