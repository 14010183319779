.detailBlock {
  border: 1px solid #eee;
  border-radius: 3px;
  margin-bottom: 30px;
}

.detailBlockHeader {
  background-color: rgba(0, 0, 0, 0.07);
  padding: 15px 20px;
}

.detailBlockCode {
  padding: 10px 15px;
}

.detailBlockTable {
  padding: 15px;
}

.detailBlockTableCollapse {
}

.detailBlockTableCollapse .ant-collapse-header {
}

.playerModal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  z-index: 99;
  opacity: 0;
  transform: translateX(-110%);
  transition: all 0.5s ease-in-out;
}
.playerModal.show {
  transform: translateX(0);
  opacity: 1;
}

.copyCodeBlock {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 10px;
  overflow: auto;
  position: relative;
}

.copyCodeBlock code {
  white-space: pre-line;

  width: 100%;
}

.copyCodeBlock .copyButtonFloating {
  position: absolute;
  right: 10px;
  top: 10px;
}
