.videoPlayerBlock {
  position: relative;
  height: 100%;
  width: 100%;
}

.videoPlayerInnerBlock {
  height: 100%;
  width: 100%;
  position: relative;
}

.videoPlayerInnerBlock .video-js {
  height: 100% !important;
}
