:root {
  --primary: '#645DD7';
  --secondary: '#E3371E';
  --teritary: '#A4969B';
  --light: '#ddd';
  --dark: '#0D1317';
  --cyan: '#89D2DC';
  --red: '#E03616';
  --danger: var(--red);
}

.w-100 {
  width: 100%;
}

.loginBg {
  background-image: url('../public/images/new-tech-5.jpg');
  background-size: cover;
}

.padding2 {
  padding: 2%;
}

.bg-white {
  background-color: white;
}

.main-content {
  padding: 2.5%;
  max-width: 1400px;
  margin: 0 auto;
}

.main-content > .ant-card {
  min-height: calc(100vh - 120px);
}

.headerApiUrl {
  background-color: #dedede;
  display: inline-flex;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
  padding: 3px 5px;
  border-radius: 3px;
}

.code {
  font-size: 18px;
}

.headerApiUrl span {
}

.table .tableHeader {
  background-color: rgba(0, 0, 0, 0.05);
  font-weight: 600;
}

.table .ant-col {
  padding: 10px 15px;
}

.table .ant-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
